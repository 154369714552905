import React from "react";

const GlobalContext = React.createContext({
  modalOpen: () => {},
  isOpenModal: true,
  setIsOpenModal: () => {},
  insert: false,
  handleInsert: () => {},
});

export default GlobalContext;
