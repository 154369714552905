import PropTypes from "prop-types";
import { StyledCard } from "./Card.Styled";

const propTypes = {
  /**
   * radius of Button
   *
   * @type { "sm" | "md" | "lg" | "xl" | "full"  }
   */
  radius: PropTypes.oneOf(["sm", "md", "lg", "xl", "full"]),
};

export function Card({ children, radius = "sm", ...restProps }) {
  return (
    <div>
      <StyledCard radius={radius} {...restProps}>
        {children}
      </StyledCard>
    </div>
  );
}

Card.propTypes = propTypes;
