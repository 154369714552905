import styled from "styled-components";
import { darken } from "polished";

import { colors, fontSize, rounded } from "@/assets/styles/settings";

const settings = {
  colors: {
    primary: colors.moron,
    secondary: colors.blue,
  },
  hover_colors: {
    primary: darken(0.1, colors.moron),
    secondary: darken(0.1, colors.blue),
  },
  height: {
    lg: "70px",
    md: "50px",
    sm: "40px",
  },
  font_sizes: {
    lg: fontSize.lg,
    md: fontSize.base,
    sm: fontSize.sm,
  },
  icon_only_font_size: {
    lg: fontSize.xxl,
    md: fontSize.xl,
    sm: fontSize.lg,
  },
  paddings: {
    lg: "16px 32px",
    md: "10px 20px",
    sm: "8px 16px",
  },
  only_icon_paddings: {
    lg: "13px",
    md: "10px",
    sm: "6px",
  },
  border_radius: {
    sm: rounded.sm,
    md: rounded.md,
    lg: rounded.lg,
    xl: rounded.xl,
    full: rounded.full,
  },
};

function getBackground({ color, outline, hover, textOnly }) {
  if (textOnly) return "transparent";
  if (!outline) {
    return hover ? settings.hover_colors[color] : settings.colors[color];
  }
  return hover ? settings.colors[color] : colors.white;
}

function getFontColor({ color, outline, hover, textOnly }) {
  if (textOnly) return settings.colors[color];
  if (!outline) {
    return colors.white;
  }
  if (hover && outline) {
    return colors.white;
  }
  return settings.colors[color];
}

function getBorderColor({ color, textOnly }) {
  if (textOnly) return "transparent";
  return settings.colors[color];
}

function getPadding({ size, iconOnly }) {
  return iconOnly ? settings.only_icon_paddings[size] : settings.paddings[size];
}

function getDisplay({ block }) {
  return block ? "block" : "inline-block";
}

function getRadiusBorder({ radius }) {
  return settings.border_radius[radius];
}

function getWidth({ block }) {
  return block ? "100%" : "auto";
}

function getDisabledBackground({ outline, color }) {
  if (!outline) {
    return colors.light_grey;
  }
  return colors.white;
}

function getDisabledBorder({ outline, color }) {
  if (!outline) {
    return colors.light_grey;
  }
  return colors.dark_grey;
}

function getFontSize({ size, iconOnly }) {
  if (iconOnly) {
    return settings.icon_only_font_size[size];
  }
  return settings.font_sizes[size];
}

export const StyledButton = styled.button`
  background: ${(props) => getBackground(props)};
  border-color: ${(props) => getBorderColor(props)};
  border-width: 1px;
  border-style: solid;
  color: ${(props) => getFontColor(props)};
  cursor: pointer;
  border-radius: ${(props) => getRadiusBorder(props)};
  padding: ${(props) => getPadding(props)};
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  display: ${(props) => getDisplay(props)};
  width: ${(props) => getWidth(props)};
  white-space: nowrap;
  font-size: ${(props) => getFontSize(props)};
  font-weight: 500;
  line-height: ${({ iconOnly }) => (iconOnly ? "0 !important" : "inherit")};
  &:active,
  &:hover {
    background: ${(props) => getBackground({ ...props, hover: true })};
    color: ${(props) => getFontColor({ ...props, hover: true })};
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    text-decoration: none;
  }
  &:disabled {
    cursor: not-allowed !important;
    background: ${(props) => getDisabledBackground(props)};
    border-color: ${(props) => getDisabledBorder(props)};
    color: ${colors.dark_grey};
    &:active,
    &:hover {
      background: ${(props) => getDisabledBackground(props)};
      border-color: ${(props) => getDisabledBorder(props)};
      color: ${colors.dark_grey};
      text-decoration: none;
    }
  }
`;
