import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/tailwind.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ContextWrapper from "./store/ContextWrapper";
import axios from 'axios';

if(window.location.host.includes('localhost')){
  axios.defaults.baseURL = 'api/';
}else if(window.location.host.includes("broadcast.kelas.digital")){
  axios.defaults.baseURL = 'https://be.kelas-digital.id/api/';
}else{
  axios.defaults.baseURL = 'https://dev-be.kelas-digital.id/api/';
}

axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded; charset=UTF-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

ReactDOM.render(
  <React.StrictMode>
    <ContextWrapper>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ContextWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
