export const colors = {
  smoke: "#D6D3D3",
  red: "#F90808",
  grey: "#A8A5A5",
  orange: "#F89C1D",
  yellow: "#F6CD3C",

  moron: "#961A1D",
  blue: "#3C8DBC",
  green: "#009959",
  light_grey: "#E5E5E5",
  dark_blue: "#236FAA",
  white: "#ffffff",
  black: "#000000",

  error_red: "#EF4444",
  error_light: "#FCA5A5",
  dark_red: "#7F1D1D",

  light_blue: "#93C5FD",

  light_yellow: "#FCD34D",
  dark_yellow: "#78350F",

  light_green: "#6EE7B7",
  dark_green: "#064E3B",
};

export const fontSize = {
  xs: "12px",
  sm: "14px",
  base: "16px",
  lg: "18px",
  xl: "20px",
  xxl: "24px",
};

export const zIndex = {
  modal: 10,
  dropdown: 5,
};

export const rounded = {
  sm: "0.125rem",
  md: "0.25rem",
  lg: "0.5rem",
  xl: "0.75rem",
  full: "9999px",
};
