import React from "react";

import { AiOutlineSchedule } from "react-icons/ai";
import { RiHomeSmile2Line } from "react-icons/ri";
import Broadcast from "@/scenes/Broadcast";

export const menu_baak = [
  {
    label: "Push Notifikasi",
    to: "/push-notifikasi",
    component: Broadcast,
    icon: AiOutlineSchedule,
  },
  {
    label: "Upload Konten",
    to: "/upload-konten",
    component: Broadcast,
    icon: AiOutlineSchedule,
  },
];
