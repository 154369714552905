import * as React from "react";
import clsx from "clsx";
import { TiThMenuOutline } from "react-icons/ti";
import { menu_baak } from "../../../constant/BAAK/mobileMenu";
import { NavLink } from "react-router-dom";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { GrClose } from "react-icons/gr";

import { useNavigate } from "react-router-dom";
import { signout } from "@/helpers/auth";

function MobileMenu() {
  const [isOpen, setIsOpen] = React.useState(true);
  let navigate = useNavigate();

  let menuMobile;

  menuMobile = (
    <div
      className={clsx(
        "bg-gray-500 rounded-lg w-2/3 opacity-95 text-white fixed top-0 left-0 h-screen overflow-hidden transition-transform duration-500 ease-in-out transform z-20",
        isOpen ? "-translate-x-full" : "translate-x-0"
      )}
    >
      <div className="my-10">
        {menu_baak.map((data, i) => (
          <p
            className="w-auto cursor-pointer rounded-lg p-2 hover:bg-oren_hover "
            key={i}
          >
            <NavLink
              to={data.to}
              className={(navData) =>
                navData.isActive
                  ? "text-oren_hover text-md rounded-lg p-2  text-black"
                  : "text-white"
              }
            >
              <p className="flex items-center font-semibold text-lg ">
                <data.icon className="text-white mr-2 w-8 h-auto" />{" "}
                {data.label}
              </p>
            </NavLink>
          </p>
        ))}

        <p
          onClick={() => {
            navigate("/");
            signout();
          }}
          className="flex p-2 my-4 items-center font-semibold text-lg"
        >
          <RiLogoutCircleRFill className="text-white mr-2 w-8 h-auto" />{" "}
          <span className="">Logout</span>
        </p>
      </div>
    </div>
  );
  return (
    <>
      <div className="w-full flex justify-between block md:hidden">
        <p className="text-center text-white font-semibold text-xl">Broadcast</p>
        <div onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <TiThMenuOutline className="w-7 h-auto" />
          ) : (
            <GrClose className="w-7 h-auto" />
          )}
        </div>
      </div>
      {menuMobile}
    </>
  );
}

export default MobileMenu;
