import logo from "../../../assets/images/KeDi-Putih-01.png";
import { Profile } from "@/components";
import { isAuth } from "@/helpers/auth";

export function Menu() {
  return (
    <div className="w-full items-center flex justify-between">
      <div>
        <img className="w-32" alt="logo kampus" src={logo} />
      </div>
      <div>
        <span className="text-lg font-extrabold text-white">Broadcast</span>
      </div>
      <div>
      { isAuth() && ( <Profile />  ) }
      </div>
    </div>
  );
}
