import styled from "styled-components";

import { colors, fontSize } from "@/assets/styles/settings";

const typeColors = {
  blue: {
    background: colors.light_blue,
    color: colors.dark_blue,
  },
  green: {
    background: colors.light_green,
    color: colors.dark_green,
  },
  red: {
    background: colors.error_light,
    color: colors.dark_red,
  },
  yellow: {
    background: colors.light_yellow,
    color: colors.dark_yellow,
  },
};

export const StyledAlert = styled.div`
  background: ${({ color }) => typeColors[color].background};
  border-radius: 4px;
  color: ${({ color }) => typeColors[color].color};
  display: flex;
  font-size: ${fontSize.base};
  align-items: center;
  padding: 8px 14px;
  width: 100%;
  margin-bottom: 16px;
  white-space: pre-wrap;
`;
