import React from "react";
import { Menu } from "@/components";

import MobileMenu from "../Elements/Menu/MobileMenu";

export function Navbar() {
  return (
    <div className="w-full px-6 py-4 items-center flex justify-between bg-purple-600 absolute">
      {/* mobile */}
      <MobileMenu />

      {/* dekstop */}
      <div className="hidden w-full md:flex items-center h-full">
        <Menu />
      </div>
    </div>
  );
}
