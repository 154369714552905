import styled from "styled-components";
import { colors, rounded } from "../../../assets/styles/settings";

const settings = {
  border_radius: {
    sm: rounded.sm,
    md: rounded.md,
    lg: rounded.lg,
    xl: rounded.xl,
    full: rounded.full,
  },
};

function getRadiusBorder({ radius }) {
  return settings.border_radius[radius];
}

export const StyledCard = styled.div`
  max-width: 100%;
  padding: 1rem;

  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  transition: all 1.3s;

  border-radius: ${(props) => getRadiusBorder(props)};
  background: ${colors.white};

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
