import { useState, useEffect } from "react";
import Layout from "@/components/Layouts/Layout";
import { toast, ToastContainer } from "react-toastify";
import { isAuth } from "@/helpers/auth";
import { getCookie } from "@/helpers/auth";
import axios from "axios";
import { useLocation } from 'react-router-dom'
import { BarPanel } from "@/components/Broadcast/BarPanel";
import { PushNotifikasi } from "@/components/Broadcast/PushNotifikasi";
import { UploadKonten } from "@/components/Broadcast/UploadKonten";
import { Navigate } from "react-router-dom";

function Broadcast() {
    let location = useLocation();
    let pathname = location.pathname;
    const [attachment, setAttachment] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [periods, setPeriods] = useState({
        start: "",
        end: "",
    });

    useEffect(() => {

    }, [isLoading]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        let myForm = document.querySelector("form");
        let formData = new FormData(myForm)
        let output = {};

        formData.forEach(( value, key ) => {
            if ( Object.prototype.hasOwnProperty.call( output, key ) ) {
                let current = output[ key ];
                if ( !Array.isArray( current ) ) {
                    current = output[ key ] = [ current ];
                }
                    current.push( value );
            } else {
                output[ key ] = value;
            }
        });

        if (pathname === "/push-notifikasi") {
            pushNotif(output);
            return;
        }

        uploadKonten(output);
        return
    }

    const pushNotif = async (data) => {
        let payload = {
            audienceId: parseInt(data.audienceId),
            announcementTypeId: 1,
            content: {
                title   : data.judul,
                body    : data.pesan
            }
        }
        delete payload.judul;
        delete payload.pesan;

        await axios.post(`announcement`, payload , {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getCookie("token"),
            },
        }).then((res) => {
            toast.success("Berhasil Post Notifikasi", {
                autoClose: 3000,
                position: toast.POSITION.TOP_CENTER,
            });
            document.getElementById("formMain").reset();
        }).catch((err) => {
            toast.error("Gagal Post Notifikasi!", {
                position: toast.POSITION.TOP_CENTER,
            });
        }).finally(() => {
            setIsLoading(false)
        });
    }

    const uploadKonten = async (data) => {
        if (attachment.length <= 0 ) {
            toast.error("Upload Image/Video terlebih dahulu!", {
                position: toast.POSITION.TOP_CENTER,
            });
            setIsLoading(false)
            return
        }

        let feedDocumentStorageIds =  await handleStorage(data.file);
        let periods = ""

        if(data.start_date){
            periods = data.start_date;
            if(data.end_date) {
                periods = `${data.start_date} - ${data.end_date}`
            }
        }

        let payload = {
            ...data,
            className: (parseInt(data.feedTypeId) == 7)?"Sponsor":data.className,
            feedTypeId: parseInt(data.feedTypeId) ?? 4,
            periods,
            feedDocumentStorageIds,
            "hyperlink" : {
                "name": data.konten_name,
                "link": data.link
            }
        }

        await axios.post(`feeds/post`, payload , {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getCookie("token"),
            },
        }).then((res) => {
            toast.success("Berhasil Menambahkan Feed", {
                autoClose: 3000,
                position: toast.POSITION.TOP_CENTER,
            });
            setAttachment([]);
            setPeriods({start: "",end: "",});
            document.getElementById("formMain").reset();
        }).catch((err) => {
            toast.error("Gagal Upload Feed!", {
                position: toast.POSITION.TOP_CENTER,
            });
        }).finally(() => {
            setIsLoading(false)
        });
    }

    const handleStorage = async (data) => {
        let file = Array.isArray(data)?data:[data];

        const uploadStoragePromises = [];

        for (let i = 0; i < file.length; i++) {
            const item = file[i];
            const formdata = new FormData();
            formdata.append("file", item);
            formdata.append("documentTypeId", 4);
            formdata.append("isPrivate", true);

            uploadStoragePromises.push(axios.post(`storage`, formdata , {
                headers: {
                    "Content-Type": "application/json",
                },
            }));
        }

        try {
            const uploadStorageList = await Promise.all(uploadStoragePromises)

            return uploadStorageList.map((resp) => {
                return parseInt(resp.data.documentStorage.id)
            })
        } catch (e) {
            toast.error("Upload Image/Video gagal", {
                position: toast.POSITION.TOP_CENTER,
            });
            setIsLoading(false)
            throw new Error('failed create feeds')
        }
    }

    return (
        <>
          {/* {isAuth() ? null : <Navigate to="/" />} */}
          <Layout>
            <ToastContainer />
            <div className="py-20 px-4 md:py-28 md:px-20 w-full justify-between block md:flex md:flex-wrap">
                <div className="shadow w-full overflow-hidden sm:rounded-md">
                    <BarPanel />
                    <div className="px-4 py-5 bg-white sm:p-6">
                        <form id="formMain" onSubmit={handleSubmit} method="POST" className="w-full" encType="multipart/form-data">
                            <fieldset>
                                { pathname === "/push-notifikasi"  && (<PushNotifikasi />) }
                                { pathname === "/upload-konten" && (
                                    <UploadKonten
                                        attachment = {attachment}
                                        periods = {periods}
                                        setAttachment = {setAttachment}
                                        setPeriods  = {setPeriods}
                                    />
                                ) }
                            </fieldset>
                        </form>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                            form="formMain"
                            type="submit"
                            className={`inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md font-medium text-white ${isLoading?" bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed ": "bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-400"} `}
                            disabled={isLoading}
                        >
                            { isLoading && (
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                )
                            }

                            { isLoading ? (
                                    <span>Processing...</span>
                                ) : (
                                    <span>Kirim</span>
                                )
                            }
                            {isLoading}
                        </button>
                    </div>
                </div>
            </div>
          </Layout>
        </>
      );
}

export default Broadcast;
