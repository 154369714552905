import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Layout from "@/components/Layouts/Layout";

import "./index.css";
import logo from "../../assets/images/logo-kampus_red.png";
import { Button } from "@/components";
import { Card } from "../../components/Elements/Card";
import { authenticate, isAuth } from "../../helpers/auth";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

function Login() {
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    textChange: "Sign In",
  });
  const { email, password, textChange } = formData;
  const handleChange = (text) => (e) => {
    setFormData({
      ...formData,
      [text]: e.target.value,
    });
  };

  let navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && password) {
      setFormData({
        ...formData,
        textChange: "Submitting",
      });
      axios
        .post(`/auth/login`, {
          email,
          password: password,
        })
        .then((res) => {
          authenticate(res, () => {
            setFormData({
              ...formData,
              email: "",
              password: "",
              textChange: "Submitted",
            });
              navigate("/push-notifikasi")
          });
        })
        .catch((err) => {
          setFormData({
            ...formData,
            email: "",
            password: "",
            textChange: "Sign In",
          });

          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      toast.error("Mohon di isi email dan Password", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Layout>
      { isAuth() ? <Navigate to="/upload-konten" /> : null }  
      <ToastContainer />
      <form
        onSubmit={handleSubmit}
        className="flex w-full h-screen	 px-4 rounded-md items-center justify-center"
      >
        <Card radius="xl">
          <p className="uppercase text-sm  lg:text-lg text-center font-semibold mb-4">
            Login{" "}
          </p>

          <div>
            <label className="text-sm font-bold text-gray-700">
              Username
            </label>          
            <input
              className="w-full form-input focus:ring-1 focus:border-purple-400  px-4 py-2 my-2 rounded-lg focus:border-transparent  border-gray-400"
              type="text"
              placeholder="Username"
              onChange={handleChange("email")}
              value={email}
            />
            </div>
          <div>
            <label className="text-sm font-bold text-gray-700">
              Password
            </label>
            <input
              className="w-full form-input focus:ring-1 focus:border-purple-400 focus:border-transparent px-4 py-2 my-2 rounded-lg border-gray-400"
              type="password"
              placeholder="Password"
              onChange={handleChange("password")}
              value={password}
            />
          </div>
          <Button
            block
            activated={false} 
            radius="lg"
            className="mt-4 bg-indigo-500 hover:bg-indigo-400"
          >
            Sign In
          </Button>
        </Card>
      </form>
    </Layout>
  );
}

export default Login;
