import React, { useState } from "react";
import GlobalContext from "./GlobalContext";

export default function ContextWrapper({ children }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [forceInsert, setForceInsert] = useState(false);

  function modalOpenHandler() {
    setIsOpenModal(!isOpenModal);
  }

  function handleRadio() {
    setForceInsert(!forceInsert);
  }

  const context = {
    modalOpen: modalOpenHandler,
    isOpenModal: isOpenModal,
    setIsOpenModal: setIsOpenModal,

    insert: forceInsert,
    setForceInsert: setForceInsert,
    handleInsert: handleRadio,
  };

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}
