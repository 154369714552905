import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

export function BarPanel() {
  let navigate = useNavigate();
  let location = useLocation();
  let pathname = location.pathname;
  const classActive = `text-gray-700 font-bold bg-white rounded-t-lg active dark:bg-gray-800 dark:text-blue-500`;
  const classDeactive = `text-gray-700 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`;

  return (
    <div className="px-4 pt-3 bg-purple-300	 text-right sm:px-6">
        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:border-gray-700 dark:text-gray-400">
            <li className="mr-2">
                <a 
                  onClick={() => {
                    navigate("/push-notifikasi");
                  }}
                  aria-current="page"
                  className={"inline-block p-4 " + ((pathname === "/push-notifikasi") ? classActive : classDeactive)}
                >
                  Push Notifikasi
                </a>
            </li>
            <li className="mr-2">
                <a 
                  onClick={() => {
                    navigate("/upload-konten");
                  }} 
                  className={"inline-block p-4 " + ((pathname === "/upload-konten") ? classActive : classDeactive)}
                >
                  Upload Konten
                </a>
            </li>
        </ul>
    </div>
  );
}

export default BarPanel;
