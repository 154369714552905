import React from "react";

function ErrorPage() {
  return (
    <div className="w-screen h-screen flex items-center ">
      <p className="text-center w-full font-bold text-5xl">
        Halaman yg anda cari tidak di temukan
      </p>
    </div>
  );
}

export default ErrorPage;
