import { Route, Routes } from "react-router-dom";

import Login from "./scenes/Auth";
import ErrorPage from "./scenes/404";
import Broadcast from "./scenes/Broadcast/";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/push-notifikasi" element={<Broadcast />} />
        <Route path="/upload-konten" element={<Broadcast />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
