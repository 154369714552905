import PropTypes from "prop-types";
import clsx from "clsx";
import { HiOutlineCog } from "react-icons/hi";

import { StyledButton } from "./Button.Styled";

const propTypes = {
  /**
   * size of Button
   *
   * @type { "sm" | "md" | "lg" }
   */
  size: PropTypes.oneOf(["sm", "md", "lg"]),

  /**
   * color of Button
   *
   * @type { "primary" | "secondary"  }
   */
  color: PropTypes.oneOf(["primary", "secondary"]),

  /**
   * radius of Button
   *
   * @type { "sm" | "md" | "lg" | "xl" | "full"  }
   */
  radius: PropTypes.oneOf(["sm", "md", "lg", "xl", "full"]),

  /**
   * button width full or not
   */
  block: PropTypes.bool,

  /**
   * button outline type or not
   */
  outline: PropTypes.bool,

  /**
   * does button disabled
   */
  disabled: PropTypes.bool,

  /**
   * does button activated, means is in some process
   */
  activated: PropTypes.bool,

  /**
   * does button only text
   */
  textOnly: PropTypes.bool,

  /**
   * does button have icon using react-icons
   */
  Icon: PropTypes.func,
};

export function Button({
  children,
  size = "md",
  color = "",
  radius = "md",
  block = false,
  outline = false,
  disabled = false,
  activated = false,
  textOnly = false,
  Icon,
  className,
  ...restProps
}) {
  const buttonClass = clsx("comp-button", className);

  return (
    <StyledButton
      className={buttonClass}
      size={size}
      color={color}
      radius={radius}
      block={block}
      outline={outline}
      disabled={disabled}
      textOnly={textOnly}
      iconOnly={Boolean(Icon) && !children}
      {...restProps}
    >
      {activated && (
        <HiOutlineCog className="animate-spin inline-block mr-2 text-xl" />
      )}
      {Icon && !activated && (
        <Icon className={clsx("inline-block", !children ? "mr-0" : "mr-2")} />
      )}
      {children}
    </StyledButton>
  );
}

Button.propTypes = propTypes;
