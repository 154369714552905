import { useState, useEffect } from "react";
import { AiOutlineLike, AiOutlineComment, AiOutlineCloseCircle } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { FaShare } from "react-icons/fa";
import { BsBookmark } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export function UploadKonten({
    attachment,
    periods,
    setAttachment, 
    setPeriods
}) {

    useEffect(() => {
        if (attachment.length){
            setFormDataFile()
        }
    }, [attachment]);

    const handleAttachment = e => {
        let unixID = Math.floor(Math.random() * 101);
        const filename = e.target.files[0].name;
        const filesize = e.target.files[0].size;
        if(filesize < 1000000){
            var _size = Math.floor(filesize/1000) + 'KB';
         }else{
            var _size = Math.floor(filesize/1000000) + 'MB';  
         }
        setAttachment(currents => [...currents, {
            id: unixID,
            filename: filename,
            filesize: _size,
            file: e.target.files[0],
        }]);
    }

    const onRemoveItem = i => {
        const uAttachment = attachment.filter((item) => i !== item.id);
        setAttachment(uAttachment);
    }

    const setFormDataFile = () => {
        const dt = new DataTransfer();
        attachment.forEach((item) => {
            dt.items.add(item.file);
        });
        document.getElementById('attachment').files = dt.files;
    }

    return (
        <div className="my-4 grid grid-cols-6 gap-6"> 
            <div className="col-span-6 sm:col-span-3">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                        <label className="block text-sm font-bold text-gray-700">
                        User Kelas Digital <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1 mb-3 flex">
                            <label className="inline-flex flex-auto items-center">
                                <input type="radio" className="form-radio" name="className" value="all user" defaultChecked={true} />
                                <span className="ml-2">All user</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-span-6">
                        <label className="block text-sm font-bold text-gray-700">
                        Type Boardcast <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1 mb-3 flex">
                            <label className="inline-flex flex-auto items-center">
                                <input type="radio" className="form-radio" name="feedTypeId" value="4" defaultChecked={true} />
                                <span className="ml-2">General</span>
                            </label>
                            <label className="inline-flex flex-auto items-center">
                                <input type="radio" className="form-radio" name="feedTypeId" value="3" />
                                <span className="ml-2">PMB</span>
                            </label>
                            <label className="inline-flex flex-auto items-center">
                                <input type="radio" className="form-radio" name="feedTypeId" value="7" />
                                <span className="ml-2">Sponsor</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-span-6">
                        <label className="block text-sm font-bold text-gray-700">
                            Nama Konten <span className="text-red-500">*</span>
                        </label>
                        <input
                        placeholder="Nama Konten"
                        name="konten_name"
                        type="text"
                        className="mt-1 focus:ring-purple-400 focus:border-purple-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        required
                        />
                    </div>
                    <div className="col-span-6">
                        <label className="block text-sm font-bold text-gray-700">
                        Tanggal Aktif
                        </label>
                        <DatePicker
                            selectsStart
                            isClearable
                            selected={periods.start}
                            onChange={(start) => setPeriods({ ...periods, start })}
                            dateFormat="d-MM-Y"
                            placeholderText="Tanggal Aktif"
                            className="w-full border rounded-md border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-yellow-500 mt-2 mb-4 focus:border-transparent"
                        />
                        <input type="hidden" name="start_date" value={ moment(periods.start).isValid()? moment(periods.start).format('DD-MM-YYYY'): ""  } />
                    </div>
                    <div className="col-span-6">
                        <label className="block text-sm font-bold text-gray-700">
                        Tanggal Berakhir
                        </label>
                        <DatePicker
                            selectsEnd
                            selected={periods.end}
                            isClearable
                            onChange={(end) => setPeriods({ ...periods, end })}
                            minDate={periods.start}
                            dateFormat="d-MM-Y"
                            placeholderText="Tanggal Berakhir"
                            className="w-full border rounded-md border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-yellow-500 mt-2 mb-4 focus:border-transparent"
                        />
                        <input type="hidden" name="end_date" value={ moment(periods.end).isValid()? moment(periods.end).format('DD-MM-YYYY'): ""  } />
                    </div>
                    <div className="col-span-6">
                        <label className="block text-sm font-bold text-gray-700">
                        Upload Image/Video <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1 px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed h-auto min-h-3 rounded-md">
                            <div className="space-y-1 text-center">
                                <button
                                    type="button"
                                    className="border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-400"
                                >
                                    <label for="attachment" className="block">
                                        <a 
                                            role="button" 
                                            aria-disabled="false"
                                            className="inline-flex no-underline justify-center py-2 px-4  text-white hover:text-white"
                                        >  
                                            Browse File
                                        </a>
                                    </label>
                                </button>
                            </div>
                            <input
                                type="file"
                                name="file"
                                id="attachment"
                                className="invisible absolute"
                                onChange={handleAttachment}
                            />
                            <div className="grid gap-4 grid-cols-1 mt-3" >
                                {attachment.map((item, i) => (
                                    <div key={item.id} className="flex justify-between items-center p-4 rounded-lg bg-gray-50">
                                        <div className="flex flex-col">
                                            {item.filename}
                                            <small className="text-zinc-600">{item.filesize}</small>
                                        </div>
                                        <a 
                                            role="buttom" 
                                            className="text-xl text-red-700 hover:text-red-600" 
                                            title="hapus"
                                            onClick={() => onRemoveItem(item.id)}
                                        >
                                            <AiOutlineCloseCircle />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-6 sm:col-span-3">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                        <label className="block text-sm font-bold text-gray-700">
                        Link/URL
                        </label>
                        <input
                        placeholder="Tulis Link"
                        name="link"
                        type="text"
                        className="mt-1 focus:ring-purple-400 focus:border-purple-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="col-span-6">
                        <label className="block text-sm font-bold text-gray-700">
                        Pesan <span className="text-red-500">*</span>
                        </label>
                        <textarea
                        placeholder="Tulis Pesan"
                        name="message"
                        rows="6"
                        type="text"
                        required
                        className="mt-1 focus:ring-purple-400 focus:border-purple-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="col-span-6">
                        <label className="block text-sm font-bold text-gray-700">
                        Pengaturan
                        </label>
                        <div className="mt-2 flex flex-col gap-2">
                            <div className="inline-flex items-center">
                                <label className="flex">
                                    <AiOutlineLike />
                                    <span className="w-28 ml-2">Suka</span>
                                    <input name="setting" type="checkbox" className="form-checkbox" defaultChecked={true} value="suka" />
                                </label>
                            </div>
                          
                            <div className="inline-flex items-center">
                                <label className="flex">
                                    <FiUsers />
                                    <span className="w-28 ml-2">Partisipan</span>
                                    <input name="setting" type="checkbox" className="form-checkbox" defaultChecked={true} value="partisipan" />
                                </label>
                            </div>
                          
                            <div className="inline-flex items-center">
                                <label className="flex">
                                    <AiOutlineComment />
                                    <span className="w-28 ml-2">Komentar</span>
                                    <input name="setting" type="checkbox" className="form-checkbox" defaultChecked={true} value="komentar" />
                                </label>
                            </div>
                           
                            <div className="inline-flex items-center">
                                <label className="flex">
                                    <FaShare />
                                    <span className="w-28 ml-2">Bagikan</span>
                                    <input name="setting" type="checkbox" className="form-checkbox" defaultChecked={true} value="bagikan" />
                                </label>
                            </div>
                          
                            <div className="inline-flex items-center">
                                <label className="flex">
                                    <BsBookmark />
                                    <span className="w-28 ml-2">Simpan</span>
                                    <input name="setting" type="checkbox" className="form-checkbox" defaultChecked={true} value="simpan" />
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default UploadKonten;
