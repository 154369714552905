import styled from "styled-components";
import { darken } from "polished";

import { colors, fontSize, zIndex } from "../../../assets/styles/settings";

const maxWidth = {
  sm: "576px",
  base: "768px",
  xl: "1024px",
};

export const ModalBackdrop = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 200ms ease-in;
  opacity: 0;
  .is-modal-show & {
    transition: opacity 300ms ease-out;
    opacity: 1;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: 1;
  z-index: ${zIndex.modal};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 200ms ease-in;
  &.is-modal-show {
    pointer-events: auto;
    opacity: 1;
    transition: all 1.3;
  }
`;

export const StyledModal = styled.div`
  z-index: 3;
  background-color: ${colors.white};
  border-radius: 8px;
  width: 100%;
  min-height: 300px;
  max-width: ${({ size }) => maxWidth[size]};
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: transform 200ms ease-in;
  transform: scale(0.95) translateY(8px);
  .is-modal-show & {
    transition: transform 300ms ease-out;
    transform: scale(1) translateY(0);
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  padding: 14px 18px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.01),
    0 2px 4px -1px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: ${fontSize.lg};
`;

export const ModalCloseButton = styled.button`
  font-size: ${fontSize.xxl};
  color: ${colors.red};
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${darken(0.2, colors.red)};
  }
`;

export const ModalBody = styled.div`
  padding: 14px;
`;
