import React from "react";
import { CgProfile } from "react-icons/cg";
import { AiFillCaretDown } from "react-icons/ai";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { signout } from "@/helpers/auth";

import { Card } from "../Card";
import { useState, useRef } from "react";
import { useOutsideClickX } from "../../../hooks/useOutSideClick";

export function Profile() {
  const [isOpen, setIsOpen] = useState(false);

  const monthRef = useRef();

  let navigate = useNavigate();

  useOutsideClickX(monthRef, () => setIsOpen(false));

  function handleMenu() {
    setIsOpen(!isOpen);
  }

  let content;

  content = (
    <div ref={monthRef} className="absolute top-12 right-10">
      <Card>
        <div
          onClick={() => {
            navigate("/");
            signout();
          }}
          className="w-full cursor-pointer hover:bg-oren_hover p-2 m-2 flex items-center"
        >
          <RiLogoutCircleRFill />
          <span className="mx-2">Logout</span>
        </div>
      </Card>
    </div>
  );
  return (
    <div className="w-auto items-center flex ">
      <div onClick={handleMenu} className="w-auto text-white mx-4 h-5 flex cursor-pointer">
        <CgProfile className="w-full h-full" />
        <p className="mx-2 items-center flex">
          ADMIN
          <span className="mx-2">
            <AiFillCaretDown />
          </span>
        </p>
      </div>
      {isOpen ? content : null}
    </div>
  );
}
