import BaseSelect from 'react-select'
import FixRequiredSelect from "../../CustomSelect/FixRequiredSelect.js";

const Select = props => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
    />
);

export function PushNotifikasi() {
    return (
        <div className="my-4 grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
                <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700">
                    User Kelas Digital
                    </label>
                    <div className="mt-1 mb-3 flex">
                        <label className="inline-flex flex-auto items-center">
                            <input type="radio" className="form-radio" name="audienceId" value="1"  defaultChecked={true}/>
                            <span className="ml-2">All user</span>
                        </label>
                        <label className="inline-flex flex-auto items-center ml-6">
                            <input type="radio" className="form-radio" name="audienceId" value="2" />
                            <span className="ml-2">Dosen</span>
                        </label>
                        <label className="inline-flex flex-auto items-center ml-6">
                            <input type="radio" className="form-radio" name="audienceId" value="3" />
                            <span className="ml-2">Mahasiswa</span>
                        </label>
                    </div>
                    <input
                        name="email"
                        type="text"
                        placeholder='Email User'
                        className="mt-1 focus:ring-purple-400 focus:border-purple-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
                <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700">
                    Universitas
                    </label>
                    <Select
                        name="universitas" 
                        placeholder='Piih Universitas'
                        required={true}
                        isClearable
                        options={[
                            { value: '1', label: 'All Universitas' },
                        ]} />
                </div>
                <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700">
                    Falkutas
                    </label>
                    <Select
                        name="falkutas"
                        placeholder='Piih Falkutas'
                        required={true}
                        isClearable
                        options={[
                            { value: '1', label: 'All Falkutas' },
                    ]} />
                </div>
                <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700">
                    Program Studi
                    </label>
                    <Select
                        name="prodi"
                        placeholder='Piih Program Studi'
                        required={true}
                        isClearable
                        options={[
                            { value: '1', label: 'All Program Studi' },
                    ]} />
                </div>
                </div>
            </div>
            <div className="col-span-6 sm:col-span-3">
                <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700">
                    Mata Kuliah
                    </label>
                    <Select
                        name="mata_kuliah"
                        placeholder='Piih Mata Kuliah'
                        required={true}
                        isClearable
                        options={[
                            { value: '1', label: 'All Mata Kuliah' },
                    ]} />
                </div>
                <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700">
                    Kelas
                    </label>
                    <Select
                        name="class"
                        placeholder='Piih Mata Kuliah'
                        required={true}
                        isClearable
                        options={[
                            { value: '1', label: 'All Mata Kuliah' },
                    ]} />
                </div>
                <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700">
                    Judul
                    </label>
                    <input
                        placeholder='Judul Notifikasi'
                        name="judul"
                        autoComplete='off'
                        type="text"
                        className="mt-1 focus:ring-purple-400 focus:border-purple-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
                <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700">
                    Pesan
                    </label>
                    <textarea
                        placeholder='Tulis Pesan'
                        name="pesan"
                        rows="6"
                        type="text"
                        className="mt-1 focus:ring-purple-400 focus:border-purple-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
                </div>
            </div>
        </div>
    );
}

export default PushNotifikasi;
